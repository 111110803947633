<template>
  <div class="page">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Remove</span>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="50px"
        class="demo-ruleForm"
      >
        <el-form-item label="Code" prop="code">
          <el-input v-model="ruleForm.code" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="btn" @click="submitForm('ruleForm')"
            >Submit</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      ruleForm: {
        code: "",
      },
      rules: {
        code: [{ required: true, message: "Code", trigger: "blur" }],
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    console.log(this.$route.query.id);
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            id: this.$route.query.id,
            code: this.ruleForm.code,
          };
          this.$http.get("/main/check", { params }).then((res) => {
            if (res.data.code === 200) {
              console.log();
              this.$confirm(`${res.data.msg}`, "Message", {
                confirmButtonText: "OK",
                cancelButtonText: "CLOSE",
                center: true,
              });
            } else {
              this.$confirm(res.data.msg, "Message", {
                confirmButtonText: "OK",
                cancelButtonText: "CLOSE",
                center: true,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  height: 100vh;
  display: flex;
  align-items: center;
  .box-card {
    height: 300px;
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }
  .demo-ruleForm {
    width: 100%;
  }
  /deep/ .el-card__header {
    border: 0;
  }

  /deep/ .el-card__body {
    width: 90%;
  }
  // .btn {
  //   width: 50%;
  //   margin-left: 25%;
  // }
  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .page {
    height: 100vh;
    display: flex;
    align-items: center;
    .box-card {
      height: 30%;
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: auto;
    }
  }
}
</style>
<style>
/* .el-button--small {
  width: 120px !important;
} */
@media screen and (max-width: 1024px) {
  .el-message-box {
    width: 90% !important;
  }
}
</style>
